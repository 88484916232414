<template>
  <nav
    class="navbar navbar-expand-lg main-navbar"
    :style="!drawer ? 'left:10px; ' : ''"
  >
    <div class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li>
          <a
          style="margin-left: 6px;"
            href="#"
            data-toggle="sidebar"
            @click="_playSideBar()"
            class="nav-link nav-link-lg collapse-btn"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <li>
          <a href="#" class="nav-link nav-link-lg fullscreen-btn">
            <i class="fas fa-expand"></i>
          </a>
        </li>
        <li>
          <b style="font-size: larger">
            <v-row style="margin-left: 40px;margin-bottom: 0px;margin-top:0px;">
              <v-img v-if="user && user._id == '63c800d7f37bc01f947d184e'" style="margin: auto 0" max-height="30" max-width="30" src="/images/cockfight-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63c8ac9254fdd74368117ef1'" style="margin: auto 0" max-height="30" max-width="30" src="/images/cockfight-icon-2.png"/>
              <v-img v-else-if="user && user._id == '63c8aca354fdd74368117f01'" style="margin: auto 0" max-height="30" max-width="30" src="/images/cockfight-icon-3.png"/>
              <v-img v-else-if="user && user._id == '63c8acb054fdd74368117f11'" style="margin: auto 0" max-height="30" max-width="30" src="/images/cockfight-icon-4.png"/>
              <v-img v-else-if="user && user._id == '642e21a9a6b8754c0666b984'" style="margin: auto 0" max-height="30" max-width="30" src="/images/baccarat-icon-4.png"/>
              <v-img v-else-if="user && user._id == '642e21c0a6b8754c0666b995'" style="margin: auto 0" max-height="30" max-width="30" src="/images/roulette-icon.png"/>
              <v-img v-else-if="user && user._id == '63c8f4cec425ff09dc4a485b'" style="margin: auto 0" max-height="30" max-width="30" src="/images/klaklouk-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63c8f4fcc425ff09dc4a486b'" style="margin: auto 0" max-height="30" max-width="30" src="/images/baccarat-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63c8f512c425ff09dc4a487b'" style="margin: auto 0" max-height="30" max-width="30" src="/images/baccarat-icon-2.png"/>
              <v-img v-else-if="user && user._id == '6465de6cbf1d7e15a80960d6'" style="margin: auto 0" max-height="30" max-width="30" src="/images/baccarat_icon_3.png"/>
              <v-img v-else-if="user && user._id == '63c8f528c425ff09dc4a488b'" style="margin: auto 0" max-height="30" max-width="30" src="/images/dragon-tiger-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63c8f535c425ff09dc4a489b'" style="margin: auto 0" max-height="30" max-width="30" src="/images/dragon-tiger-icon-2.png"/>
              <v-img v-else-if="user && user._id == '6465de7abf1d7e15a80960e7'" style="margin: auto 0" max-height="30" max-width="30" src="/images/dragon_tiger_icon_3.png"/>
              <v-img v-else-if="user && user._id == '63c8f546c425ff09dc4a48ab'" style="margin: auto 0" max-height="30" max-width="30" src="/images/lotto-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63c8f559c425ff09dc4a48bb'" style="margin: auto 0" max-height="30" max-width="30" src="/images/yuki-icon-1.png"/>
              <v-img v-else-if="user && user._id == '63cf32c015e0d051746e978f'" style="margin: auto 0" max-height="30" max-width="30" src="/images/apong-icon-1.png"/>
              <v-img v-else-if="user && user._id == '642e21eda6b8754c0666b9a6'" style="margin: auto 0" max-height="30" max-width="30" src="/images/apong-icon-2.png"/>
              <v-img v-else-if="user && user._id == '640ab227aa43c524c47a61b0'" style="margin: auto 0" max-height="30" max-width="30" src="/images/fantan-icon-1.png"/>
              <v-img v-else-if="user && user._id == '640ab018aa43c524c47a605e'" style="margin: auto 0" max-height="30" max-width="30" src="/images/taisiev-icon-1.png"/>
              <v-img v-else-if="user && user._id == '649120a9b13ec03f3895a8be'" style="margin: auto 0" max-height="30" max-width="30" src="/images/han2_1.png"/>
              <v-img v-else-if="user && user._id == '64900e4dac3c0718cc2fd9e7'" style="margin: auto 0" max-height="30" max-width="30" src="/images/han3_1.png"/>
              
              {{ user ? user.user_name : "" }}:
              <span style="font-weight: bold; color: darkgoldenrod" v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'">
                {{ user ? currencyFormatUsd(user.companyBalances[0].balance) + "$, " 
                + currencyFormatKm(user.companyBalances[1].balance) + "៛, " 
                + currencyFormatKm(user.companyBalances[2].balance) + "₫, " 
                + currencyFormatKm(user.companyBalances[3].balance) + "Rp ": "" }} 
                <!-- <span v-if="user && user.role_id == '612a3fcc1a1fcead8871e822' && user.companyBalances[2]">{{  currencyFormatKm(user.companyBalances[2].balance) + "₫"  }}</span> -->
              </span>
              <span style="font-weight: bold; color: darkgoldenrod" v-else-if="user && user.role_id == '6191dc644825e6f57ffa231f' || user && user.role_id == '6191dce94825e65937fa2340' ">
                {{ user ? currencyFormatUsd(user.agent_balance) : "" }}
                <span>{{ getUserCurrency() }}</span>
              </span>
              <span style="font-weight: bold; color: darkgoldenrod" v-else>
                {{ user ? currencyFormatUsd(user.balance) : "" }}
                <span>{{ getUserCurrency() }}</span>
              </span>
            </v-row>
          </b>
        </li>
      </ul>
    </div>
    <v-btn class="text-none" stacked v-if="user && (user.role_id == '61829dd1e64b1b17688326df' ||user.role_id == '61829dc7e64b1b17688326d5' ||user.role_id == '6424071f50b77c6cf890ab0d' )"
    @click="readNotification"
    >
      <v-badge bordered color="error" v-if="user.is_read == false">
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
      <v-badge v-else color="">
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
    </v-btn>
    <ul class="navbar-nav navbar-right">
      <a @click="onLogout" class="btn btn-danger" style="color: white">
        Logout
      </a>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters("$_modules", {
      drawer: "getDrawer",
      user: "getUser",
    }),
  },
  async created(){
    await this.getUserInfo();
    this.isSuspend();
  },
  async mounted() { 
    this.$socket.on("server-notification", data => {
      if (data) {
        this.user.is_read = false;
      }
    });
  },
  methods: {
    _playSideBar() {
      this.UPDATE_DRAWER(!this.drawer);
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
        num = parseFloat(num);
        return parseInt(num).toLocaleString("en");
      },
    getUserCurrency() {
      if (this.user.currency_type === 1) {
        return "$";
      } else if (this.user.currency_type === 2) {
        return "៛";
      } else if (this.user.currency_type === 3) {
        return "฿";
      }else if (this.user.currency_type === 4) {
        return "₫";
      }else if (this.user.currency_type === 5) {
        return "Rp";
      } else {
        return this.user.currency_type;
      }
    },
    async getUserInfo() {
      await this.fetchUser();
    },
    isSuspend() {
      if (this.user) {
        if (this.user.is_suspend) {
          this.onLogout()
        }
      }
    },
    async onLogout() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        let data = {
          barcode: dealerId.barcode,
        };
        await this.$request.post({
          url: "dealer/inActiveDealer",
          data: data,
        });
      }
      this.$cookies.remove("dealerId");
      this.$cookies.remove("token");
      this.$router.push("/login");
    },
    async readNotification(){
      let data = {};
      await this.$request
        .post({
          url: "/bankStatement/readNotification",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.user.is_read = true;
            this.$router.push("/bankStatement");
            this.$router.go("/bankStatement");
          }
        });
    },
    ...mapMutations("$_modules", ["UPDATE_DRAWER"]),
    ...mapActions("$_modules", [ "fetchUser"]),
  },
};
</script>
<style scoped>
.font {
  font-family: "SegoeUI", "Koh Santepheap" !important;
}
</style>